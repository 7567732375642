import React, { Component } from 'react';

const regions = {
  1: { default: { quote: <span>pay <b>50c to unlock</b> and just <b>32c</b> per minute!</span>, savings: '50%' } },
  5: { default: { quote: <span>pay <b>50c to unlock</b> and just <b>27c</b> per minute!</span>, savings: '50%' } },
  9: { default: { quote: <span>pay <b>50c to unlock</b> and just <b>27c</b> per minute!</span>, savings: '50%' }, student: { quote: <span>pay <b>no unlocking fees</b> and just <b>40c</b> per minute!</span>, savings: '40%' } },
  13: { default: { quote: <span>pay <b>50c to unlock</b> and just <b>32c</b> per minute!</span>, savings: '50%' } },
  15: { default: { quote: <span>pay <b>50c to unlock</b> and just <b>22c</b> per minute!</span>, savings: '50%' }, student: { quote: <span>pay <b>no unlocking fees</b> and just <b>45c</b> per minute!</span>, savings: '25%' } },
};

class DiscountsCalculator extends Component {
  constructor() {
    super();
    this.state = {
      type: false,
      region: false,
      method: false,
    };

    this.handleType = this.handleType.bind(this);
    this.handleRegion = this.handleRegion.bind(this);
  }

  handleType(e) {
    const method = e.target.value === 'leisure' ? 'To apply, forward a copy of your membership email to hello@flamingo.co.nz.' : 'To apply, tap "Change Plan" from the Payment screen of the Flamingo app.'
    this.setState({ type: e.target.value, method });
  }

  handleRegion(e) {
    this.setState({ region: e.target.value });
  }

  render() {
    const { type, region, method } = this.state;
    const result = (type && region) ? (regions[region][type] || regions[region].default) : false;

    return (
      <>
        <div className="fm-discounts-calculator">
          <div className="fm-discounts-calculator-container">
            <p className="fm-discounts-calculator-title">How to Apply</p>
            <p className="fm-discounts-calculator-description">Select your card type and city below to see how to apply and how much you could save on Flamingo's Community and Student Plans.</p>
            <div className="fm-discounts-calculator-form">
              <select className="fm-discounts-calculator-form-select" defaultValue="-1" onChange={this.handleType}>
                <option disabled="disabled" value="-1">Select your card type</option>
                <option value="student">Student ID</option>
                <option value="supergold">SuperGold Card</option>
                <option value="community">Community Services Card</option>
                <option value="totalmobility">Total Mobility Card</option>
                <option value="hapaiaccess">Hāpai Access Card</option>
                <option value="leisure">LeisureCard (Wellington)</option>
              </select>
              <select className="fm-discounts-calculator-form-select" defaultValue="-1" onChange={this.handleRegion}>
                <option disabled="disabled" value="-1">Select your city</option>
                <option value="1">Wellington</option>
                <option value="9">Palmerston North</option>
                <option value="13">Porirua</option>
                <option value="15">Dunedin</option>
                <option value="5">Waimakariri</option>
              </select>
            </div>
            {
              result && <p className="fm-discounts-calculator-result">With this plan you will { result.quote } Saving approximately <b>{ result.savings }</b> off the average ride.</p>
            }
          </div>
        </div>
        {
          (result && method) &&
          <div className="fm-discounts-blurb">
            <div className="fm-discounts-blurb-container">
              <p className="fm-discounts-blurb-text">{ method }</p>
            </div>
          </div>
        }
      </>
    );
  }
}

export default DiscountsCalculator;
