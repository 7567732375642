import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Locations extends Component {
  render() {
    return (
      <div className="fm-home-splash-locations">
        <div className="fm-home-splash-locations-container">
          <p className="fm-home-splash-locations-title">Cities</p>
          <div className="fm-home-splash-locations-items">
            { /*
            <Link className="fm-home-splash-locations-item" to="/city/auckland">
              <div className="fm-home-splash-locations-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/auckland.jpg')` }}></div>
              <p className="fm-home-splash-locations-item-title">Auckland</p>
            </Link>
            */ }
            <Link className="fm-home-splash-locations-item" to="/city/wellington">
              <div className="fm-home-splash-locations-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/wellington-scooter.jpg')` }}></div>
              <p className="fm-home-splash-locations-item-title">Wellington</p>
            </Link>
            <Link className="fm-home-splash-locations-item" to="/city/porirua">
              <div className="fm-home-splash-locations-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/porirua-scooter-2.jpg')` }}></div>
              <p className="fm-home-splash-locations-item-title">Porirua</p>
            </Link>
            <Link className="fm-home-splash-locations-item" to="/city/palmerston-north">
              <div className="fm-home-splash-locations-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/palmy.jpg')` }}></div>
              <p className="fm-home-splash-locations-item-title">Palmerston North</p>
            </Link>
            <Link className="fm-home-splash-locations-item" to="/city/dunedin">
              <div className="fm-home-splash-locations-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/dunedin.jpg')` }}></div>
              <p className="fm-home-splash-locations-item-title">Dunedin</p>
            </Link>
            <Link className="fm-home-splash-locations-item" to="/city/waimakariri">
              <div className="fm-home-splash-locations-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/waimakariri.jpg')` }}></div>
              <p className="fm-home-splash-locations-item-title">Waimakariri</p>
            </Link>
            <div className="fm-home-splash-locations-item"></div>
            { /*
            <Link className="fm-home-splash-locations-item" to="/city/christchurch">
              <div className="fm-home-splash-locations-item-image" style={{ backgroundImage: `url('https://storage.googleapis.com/flamingo-static/images/facade/christchurch.jpg')` }}></div>
              <p className="fm-home-splash-locations-item-title">Christchurch</p>
            </Link>
            */ }
          </div>
        </div>
      </div>
    );
  }
}

export default Locations;
