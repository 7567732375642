import React, { Component } from 'react';
import SplashImage from './components/splash-image';
import Blurb from './components/blurb';
import GettingStarted from './components/getting-started';
import Safety from './components/safety';
import Links from './components/links';
import history from '../../helpers/history';
import api from '../../helpers/api';

const vehicles = {
  scooter: {
    name: 'Flamingo Scooter',
    instructionGroupId: 7,
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-scooter.jpg',
    catch: 'The fourth generation Flamingo e-scooter.',
    blurb: 'The safest sharing e-scooter in New Zealand, with dual suspension and braking you can ride comfortably across town. Our scooters have a top speed of 25 km/h.',
    started: [
      {
        title: 'Find and Scan',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-find.jpg',
        description: 'Find Flamingo scooters through the Flamingo app. Scan the QR code on the top of the scooter within the app.',
      },
      {
        title: 'Start Riding',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-ride.jpg',
        description: 'Push off with one foot, once you reach 5 km/h engage the throttle. Use the front and back brakes to stop.',
      },
      {
        title: 'Park Considerately',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-end.jpg',
        description: 'Park your Flamingo to the side, ideally against street furniture. Keep paths and driveways clear.',
      },
    ]
  },
  bike: {
    name: 'Flamingo Bike',
    instructionGroupId: 8,
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-bike-2.jpg',
    catch: 'The newest addition to the Flamingo fleet.',
    blurb: 'The Flamingo Bike is electric assisted, the onboard intelligent system automatically applies power as you pedal based on the cycling environment. Our bikes have a top assisted speed of 32 km/h.',
    started: [
      {
        title: 'Find and Scan',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/bike-how-find.jpg',
        description: 'Find Flamingo bikes through the Flamingo app. Scan the QR code on the top of the bike within the app.',
      },
      {
        title: 'Start Riding',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/bike-how-ride.jpg',
        description: 'Begin pedalling, the electic assist will automatically kick in. Use the front and back brakes to stop.',
      },
      {
        title: 'Park Considerately',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/bike-how-end.jpg',
        description: 'Park your Flamingo to the side, ideally against street furniture. Keep paths and driveways clear.',
      },
    ]
  },
  'scooter-5': {
    name: 'Flamingo Scooter',
    instructionGroupId: 15,
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-to-scooter.jpg',
    catch: 'The fifth generation Flamingo e-scooter.',
    blurb: 'The safest sharing e-scooter in New Zealand, with dual suspension and braking you can ride comfortably across town. Our scooters have a top speed of 25 km/h.',
    started: [
      {
        title: 'Find and Scan',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-find.jpg',
        description: 'Find Flamingo scooters through the Flamingo app. Scan the QR code on the top of the scooter within the app.',
      },
      {
        title: 'Start Riding',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-ride.jpg',
        description: 'Push off with one foot, once you reach 5 km/h engage the throttle. Use the front and back brakes to stop.',
      },
      {
        title: 'Park Considerately',
        image: 'https://storage.googleapis.com/flamingo-static/images/facade/how-end.jpg',
        description: 'Park your Flamingo to the side, ideally against street furniture. Keep paths and driveways clear.',
      },
    ]
  },
};

class HowTo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle: vehicles[props.match.params.vehicle],
      loadingInstructions: true,
    };
    this.loadInstructions = this.loadInstructions.bind(this);
  }

  componentDidMount() {
    const { vehicle } = this.state;
    if (!vehicle) {
      history.push('/');
    }
    document.title = `${vehicle.name} | Flamingo`;
    this.loadInstructions(vehicle.instructionGroupId);
  }

  loadInstructions(instructionGroupId) {
    return api.get(`/facade/instruction/${instructionGroupId}`)
      .then((res) => this.setState({ instruction: res.data.data, loadingInstructions: false }))
      .catch(() => {});
  }

  render() {
    const {
      vehicle,
      loadingInstructions,
      instruction,
    } = this.state;

    if (!vehicle) {
      history.push('/');
    }

    return (
      <div className="fm-how-to">
        <SplashImage vehicle={vehicle} />
        <Blurb vehicle={vehicle} />
        <GettingStarted vehicle={vehicle} />
        <Safety vehicle={vehicle} instruction={instruction} loading={loadingInstructions} />
        <Links />
      </div>
    );
  }
}

export default HowTo;
